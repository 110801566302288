<template>
  <v-card elevation=4 outlined class="rounded-lg">
    <v-card-text>
      <v-form v-model="valid" @input="$emit('update:changes_valid', valid)">
        <v-row>
          <v-col cols=12>
            <v-text-field label="Username" v-model="user.username" :rules="rules.username" :readonly="!access.update_access" outlined></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols=6>
            <v-text-field label="Given Name" v-model="user.given_name" :rules="rules.given_name" :readonly="!access.update_access" outlined></v-text-field>
          </v-col>

          <v-col cols=6>
            <v-text-field label="Family Name" v-model="user.family_name" :rules="rules.family_name" :readonly="!access.update_access" outlined></v-text-field>
          </v-col>
        </v-row>
        
        <v-row>
          <v-col cols=12>
            <v-text-field label="Email" v-model="user.email" :rules="rules.email" :readonly="!access.update_access" type="email" outlined></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols=12>
            <v-select
              label="Role"
              v-model="user.role_id"
              :items="roles"
              item-text="role_name"
              item-value="role_id"
              :rules="rules.role"
              outlined
              hide-details
            ></v-select>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      valid: true
    }
  },
  props: {
    user: Object,
    roles: Array,
    rules: Object,
    access: Object,
  }
}
</script>

<style>

</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('Header',{attrs:{"title":"Edit User","divider":false,"options":[
    {
      'title': 'Delete',
      'icon': 'mdi-delete',
      'disabled': !_vm.access.delete_access,
      'action': () => {this.$refs.delete_dialog.showDialog().then(() => _vm.deleteUser()).catch(() => {})}
    }
  ]}}),_c('UnsavedChanges',{attrs:{"text":"User Has Unsaved Changes","changes":_vm.changes,"changes_valid":_vm.changes_valid,"saveChanges":_vm.saveChanges,"discardChanges":_vm.discardChanges}}),_c('ActionDialog',{ref:"delete_dialog",attrs:{"title":"Deleting User","color":"error"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('span',{staticClass:"error--text",domProps:{"innerHTML":_vm._s('Are you sure you want to delete this user.<br>Warning! All access will be revoked from this user.')}})]},proxy:true}])}),_c('UserTabs',{attrs:{"user":_vm.newData.user,"roles":_vm.roles,"access":_vm.access,"rules":_vm.rules,"loading":_vm.loading,"changes_valid":_vm.changes_valid},on:{"update:user":function($event){return _vm.$set(_vm.newData, "user", $event)},"update:changes_valid":function($event){_vm.changes_valid=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
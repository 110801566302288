<template>
  <v-container fluid>
    <Header
    title="Edit User"
    :divider="false"
    :options="[
      {
        'title': 'Delete',
        'icon': 'mdi-delete',
        'disabled': !access.delete_access,
        'action': () => {this.$refs.delete_dialog.showDialog().then(() => deleteUser()).catch(() => {})}
      }
    ]"
    ></Header>

    <!-- Unsaved Changes Barids -->
    <UnsavedChanges
      text="User Has Unsaved Changes"
      :changes="changes"
      :changes_valid="changes_valid"
      :saveChanges="saveChanges"
      :discardChanges="discardChanges"
    ></UnsavedChanges>

    <ActionDialog ref="delete_dialog" title="Deleting User" color="error">
      <template v-slot>
        <span class="error--text" v-html="'Are you sure you want to delete this user.<br>Warning! All access will be revoked from this user.'"></span>
      </template>
    </ActionDialog>

    <UserTabs
      :user.sync="newData.user"
      :roles="roles"
      :access="access"
      :rules="rules"
      :loading="loading"
      :changes_valid.sync="changes_valid"
    ></UserTabs>
  </v-container>
</template>

<script>
var clone = require('clone')
import Header from "@/components/Header.vue"
import UnsavedChanges from "@/components/UnsavedChanges.vue"
import ActionDialog from "@/components/ActionDialog.vue"
import UserTabs from "@/components/settings/user/UserTabs.vue"
export default {
  data() {
    return {
      newData: {
        user: {}
      },
      oldData: {
        user: {}
      },
      roles: [],
      loading: false,
      changes_valid: true,
      access: this.$store.getters.getAccessLevel['users'],
      rules: {
        username: [v => !!v || 'Username is required'],
        given_name: [v => !!v || 'Given name is required'],
        family_name: [v => !!v || 'Family name is required'],
        email: [v => !!v || 'Email is required', v => /.+@.+\..+/.test(v) || 'Email must be valid'],
        role: [v => !!v || 'Role is required'],
      }
    }
  },
  components: {
    Header,
    UnsavedChanges,
    ActionDialog,
    UserTabs,
  },
  mounted: function () {
    this.getUser();
    this.getRoles();
  },
  computed: {
    changes() {
      let user_changed = JSON.stringify(this.oldData.user) !== JSON.stringify(this.newData.user);
      return user_changed
    }
  },
  methods: {
    getUpdatesRequired() {
      let updates = {};

      for (var key in this.newData.user) {
        if (key === 'user_access') {
          let user_access = {};
          for (var access_key in this.newData.user.user_access) {
            if (JSON.stringify(this.newData.user.user_access[access_key]) !== JSON.stringify(this.oldData.user.user_access[access_key])) {
              user_access[access_key] = JSON.stringify(this.newData.user.user_access[access_key]);

              updates[key] = user_access;
            }
          }
        } else if (this.newData.user[key] !== this.oldData.user[key]) {
          updates[key] = this.newData.user[key];
        }
      }

      // Return updates as an object with properties of
      // values that have changed and need to be updated
      return updates
    },
    saveChanges() {
      this.$store.dispatch("performingUpdate", true);
      let updates_required = this.getUpdatesRequired();
      this.$axios.put(`${process.env.VUE_APP_API_BASE_URL}/users/${this.$route.params.id}`, updates_required)
        .then(() => {
          // Update the old value
          this.$set(this.oldData, 'user', clone(this.newData.user));

          this.$store.dispatch("performingUpdate", false);
          this.$store.dispatch("showSnackbar", {text: "User Updated Successfully!", color: "success", timeout: 5000});
        })
        .catch(err => {
          console.log(err);
        });
    },
    discardChanges() {
      this.newData.user = clone(this.oldData.user);
      this.$store.dispatch("showSnackbar", {text: "Changes Discarded Successfully!", color: "success", timeout: 5000});
    },
    deleteUser() {
      this.$store.dispatch("performingUpdate", true);
      this.$axios.delete(`${process.env.VUE_APP_API_BASE_URL}/users/${this.$route.params.id}`)
        .then(() => {
          this.$store.dispatch("performingUpdate", false);
          this.$router.push('/settings');
          this.$store.dispatch("showSnackbar", {text: "User Deleted Successfully!", color: "success", timeout: 5000});
        })
        .catch(err => {
          console.log(err);
          this.$store.dispatch("performingUpdate", false);
          this.$store.dispatch("showSnackbar", {text: `${err.response.statusText}!`, color: "error", timeout: 5000});
        });
    },
    getUser() {
      this.$axios.get(`${process.env.VUE_APP_API_BASE_URL}/users/${this.$route.params.id}`)
        .then(result => {
          this.$set(this.newData, 'user', result.data);
          this.$set(this.oldData, 'user', clone(result.data));
        })
        .catch(err => {
          console.log(err);
        });
    },
    getRoles() {
      this.$axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/users/roles`)
        .then(result => {
          this.roles = result.data;
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
}
</script>
<template>
  <v-card elevation=4 outlined class="rounded-lg">
    <v-card-text>
      <v-form v-model="valid">
        <v-row>
          <v-col cols=12>
            <v-autocomplete
              label="Deny Website Access"
              v-model="user.user_access.website_no_access"
              :items="websites"
              item-text="name"
              item-value="website_id"
              :readonly="!access.update_access"
              :clearable="access.update_access"
              outlined
              chips
              small-chips
              deletable-chips
              multiple
              hide-details
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols=12>
            <v-autocomplete
              label="Deny Brand Access"
              v-model="user.user_access.brand_no_access"
              :items="brands"
              item-text="name"
              item-value="brand_id"
              :readonly="!access.update_access"
              :clearable="access.update_access"
              outlined
              chips
              small-chips
              deletable-chips
              multiple
              hide-details
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { brandsCore } from "@/mixins/brandsMixin.js";
import { websitesCore } from "@/mixins/websitesMixin.js";
export default {
  mixins: [brandsCore, websitesCore],
  data() {
    return {
      valid: true,
    }
  },
  props: {
    user: Object,
    access: Object,
  }
}
</script>
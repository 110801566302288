<template>
  <v-window style="overflow: visible;">
    <v-btn-toggle v-model="current_tab" mandatory style="justify-content: space-evenly;" class="background py-4" active-class="current-tab">
      <v-row>
        <v-col v-for="(tab, i) in tabs" :key="i">
          <v-btn elevation=4 class="button-style rounded-lg" :value="tab">{{ tab }}</v-btn>
        </v-col>
      </v-row>
    </v-btn-toggle>
    <v-divider class="mt-1 pb-5" />

    <!-- User Details Card -->
    <div v-if="current_tab === 'User Details'">
      <v-row v-if="loading" dense>
        <v-col cols="12" md="6">
          <v-skeleton-loader
            type="card-heading, list-item-three-line, image"
            elevation=2
            style="margin-bottom: 12px;"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="card-heading, list-item-three-line, list-item-three-line"
            elevation=2
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="6">
          <v-skeleton-loader
            type="image, actions"
            elevation=2
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <!-- User Main Details -->
          <UserDetails
          :user.sync="user"
          :roles="roles"
          :rules="rules"
          :access="access"
          v-on="$listeners"
          ></UserDetails>
        </v-col>
      </v-row>
    </div>

    <!-- User Access Card -->
    <div v-if="current_tab === 'User Access'">
      <v-row v-if="loading" dense>
        <v-col cols=12>
          <v-skeleton-loader
            type="card-heading, divider, list-item-three-line, card-heading, list-item-two-line"
            elevation=2
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <!-- User Access Details -->
          <UserAccess
          :user.sync="user"
          :access="access"
          ></UserAccess>
        </v-col>
      </v-row>
    </div>

    <!-- Audit Log Card -->
    <div v-if="current_tab === 'Audit Logs'">
      <AuditLog
        :logs="auditLogs"
      ></AuditLog>
    </div>
  </v-window>
</template>

<script>
import { auditLogsLogic } from '@/mixins/auditLogsMixin.js'
import UserDetails from "@/components/settings/user/UserDetails.vue"
import UserAccess from "@/components/settings/user/UserAccess.vue"
import AuditLog from "@/components/auditLogs/AuditLog.vue"
export default {
  mixins: [auditLogsLogic],
  data() {
    return {
      current_tab: null,
      tabs: ['User Details', 'User Access', 'Audit Logs'],
      auditLogs: [],
    }
  },
  props: {
    user: Object,
    roles: Array,
    access: Object,
    rules: Object,
    loading: Boolean,
  },
  components: {
    UserDetails,
    UserAccess,
    AuditLog,
  },
  watch: {
    async current_tab(tab) {
      if (tab === 'Audit Logs') {
        this.auditLogs = await this.getAuditLogs(this.user.user_id, 'user');
      }
    }
  }
}
</script>